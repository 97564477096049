<template>
  <yandex-map
      :coords="center"
      :zoom="zoom"
      class="y-map"
      height="500px"
  >
    <ymap-marker v-for="marker in markers" :coords="marker.GPSTag" :key="marker.id" :marker-id="String(marker.id)"/>
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

import { loadYmap } from 'vue-yandex-maps';
import {uuidv4} from "../../plugins/Helpers";


export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    city: Number,
  },
  components: { yandexMap, ymapMarker },
  data() {
    return {
      center: [49.83333, 73.1658],
      zoom: 5
    }
  },
  computed:{
    markers() {
      return this.options.map(function(item) {
        if(item.GPStag) {
          return {
            id: uuidv4(),
            doctorName: item.doctorName,
            doctorPhone: item.doctorPhone,
            main_assist: item.main_assist,
            name: name,
            GPSTag: item.GPStag?.split(',').map(st => Number(st.trim()))
          }
        }
      }).filter(item => !!item)
    },
    centered() {
      return this.options.map(item => (item.GPStag?.split(','))?.map(st => st.trim()))
    }
  },
  watch: {
    markers(newValue) {
      if(newValue?.length) {
        this.center = newValue[0].GPSTag;
        this.zoom = 12;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.y-map{
 height: 500px;
  ymaps{
    height: 500px !important;
  }
}
</style>
<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Наименование услуги</th>
          <th>Лимит</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="expense in expenses">
          <td>{{ expense.serviceName }}</td>
          <td>{{ expense.limit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "MedCardExpenses",
  props: {
    medicalId: {
      type: Number,
      default: null
    }
  },
  data(){
    return {
      expenses: []
    }
  },
  async mounted(){
    if (this.medicalId) {
      const loader = this.$loading.show();
      try{
        const { data } = await window.axios.get(`/med-cards/expenses/${this.medicalId}`)
        this.expenses = data
      } catch (e) {

      }finally {
        loader.hide()
      }
    }
  },
  methods:{

  },
}
</script>

<style lang="scss" scoped>
table{
  border-collapse: collapse;

  thead{
    background: #E7E7E7;
    th{
      padding: 12px;
      border-collapse: separate;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;

      color: #2A353D;

      &:first-child{
        border-top-left-radius: 12px;
      }
      &:last-child{
        border-top-right-radius: 12px;
      }
    }
  }
  tbody {
    tr{
      border: 1px solid #E7E7E7;
      td{
        border: 1px solid #E7E7E7;
        padding: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &:first-child{
          width: 65%;
        }
        &:last-child{
          text-align: center;
        }
      }
    }

  }
}
</style>
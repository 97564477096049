<template>
  <div>
    <template v-if="familyMembers && familyMembers.length">
      <div class="mx-2 wrapper my-3" v-for="insured in familyMembers">
        <div class="main-info">
          <div>
            <label>Сақтанушы / Страхователь</label>
            <div class="as-input">{{ insured.assuredName }}</div>
          </div>
          <div>
            <label>Сақтандырылушы / Застрахованный</label>
            <div class="as-input">{{ insured.insuredName }}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <label>Сақтандыру кезеңі / Период страхования</label>
              <div class="as-input">{{ insuredPeriod(insured) }}</div>
            </div>
            <div>
              <label>Карточка № / Карточки</label>
              <div class="as-input">{{ insured.cardNo }}</div>
            </div>
          </div>
        </div>
        <div class="subtitle">
          СЫРҚАТТАНУ ЖАҒДАЙЫНАН САҚТАНДЫРУ / СТРАХОВАНИЕ НА СЛУЧАЙ БОЛЕЗНИ
        </div>
        <div class="footer d-flex justify-content-between">
          <div class="logo-img">
            <img src="/img/sk-logo.svg" alt="">
          </div>
          <div class="program-name">
            <p>Сақтандыру бағдарламасы</p>
            <p class="muted">Программа страхования</p>
            <div class="as-input">
              {{ insured.medicalProgram }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p>Не найдены застрахованные</p>
    </template>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MedCardInsuredItem",
  props: {
    item: {
      type: Object
    },
    familyMembers: {
      type: Array
    }
  },
  computed: {
    insuredPeriod() {
      return agreement => `${moment(agreement?.cardBeginDate?.date).format("DD.MM.YYYY")} - ${moment(agreement?.cardEndDate?.date).format("DD.MM.YYYY")}`
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  font-family: 'Manrope';
  background: #FFFFFF;
  border-radius: 15px;
}

.main-info{
  padding: 5px 15px 10px;
  background: #00805F;
  border-radius: 15px 15px 0 0;
  color: white;

  label{
    font-size: 8px;
    color: white;
  }
  .as-input {
    background: #FFFFFF;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.37);

    font-weight: 600;
    font-size: 11px;

    overflow: auto;
    white-space: nowrap;
    padding: 5px;
  }
}

.subtitle{
  height: 20px;
  background: #FFA800;

  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  color: #FFFFFF;
  text-align: center;

  padding-top: 5px;
}

.footer{
  padding: 15px;
  .logo-img {
    height: 37px;
  }
  p{
    padding: 0;
    margin: 0;
    text-align: center;
    &.muted{
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 5px;
    }
  }
  .program-name{
    font-style: normal;
    font-weight: 700;
    font-size: 7px;

    .as-input{
      background: #FFFFFF;
      border: 1px solid #525252;
      box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.37);

      font-weight: 600;
      font-size: 11px;

      text-align: center;

      color: #000000;
    }
  }
}
</style>
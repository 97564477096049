<template>
  <default-layout :header-title="$t('agreements')" :hide-header="true">
    <p class="w-title">{{ $t('agreements') }}</p>
    <v-dialog />
    <div class="agreements-page">
      <AgreementNavigation/>

      <TheTabs :tabs="tabs" @active-tab="changeActiveTab">
        <component
            :is="activeComponentTab"
            :medical-id="medicalId"
            @medical-id-selected="changeMedicalId"
        />
      </TheTabs>
    </div>
  </default-layout>
</template>

<script>
import AgreementNavigation from "../components/AgreementNavigation.vue";
import TheTabs from "../components/TheTabs.vue";
import MedCardInsuredList from '../components/MedCard/InsuredList.vue'
import MedCardExpenses from '../components/MedCard/Expenses.vue'
import MedCardCenters from '../components/MedCard/Centers.vue'
export default {
  name: "MedCards",
  components: {
    TheTabs,
    AgreementNavigation,
    MedCardInsuredList,
    MedCardExpenses,
    MedCardCenters
  },
  computed: {
    activeComponentTab(){
      if(this.activeIndex === 0) {
        return "MedCardInsuredList"
      }else if (this.activeIndex === 1) {
        return "MedCardCenters"
      } else if(this.activeIndex === 2) {
        return "MedCardExpenses"
      }
    }
  },
  data() {
    return {
      activeIndex: 0,
      medicalId: null,
      tabs: [
        {id: 1, name: this.$i18n.t('medInsureds'), route: 'agreements'},
        {id: 2, name: this.$i18n.t('medCenterAddresses'), route: 'archive-agreements'},
        {id: 3, name: this.$i18n.t('medPrograms'), route: 'med-cards'}
      ],
    }
  },
  methods: {
    changeActiveTab(tabIndex) {
      this.activeIndex = tabIndex
    },
    changeMedicalId(medicalID) {
      this.medicalId = medicalID
    }
  }
}
</script>
<template>
  <div class="px-1">
    <ul class="centers-tabs tabs">
      <li class="tab"
          v-for="(item, index) in tabs"
          :class="{'active': showAs === index}"
          @click="changeDisplay(index)"
          :key="index"
      >
        {{ item.name }}
      </li>
    </ul>

    <div class="form-group">
      <div class="wrapper">
        <search-select
            :options="availableCities"
            :reduce="item => item.cityId"
            placeholder="Выберите город"
            label="city"
            v-model="city"
            :is-block="true"
            :is-label-shown="true"
        />
      </div>
      <div class="wrapper">
        <search-select
            :options="availableCenters"
            :is-block="true"
            :is-label-shown="true"
            v-model="org"
            placeholder="Выберите учреждение"
            label="main_assist"
        />
      </div>
    </div>

    <component
        :is="showAsComponent"
        :options="filteredCenters"
        :city="city"
    />

  </div>
</template>

<script>
import SearchSelect from "../BuyPolicy/SearchSelect.vue";
import ListTab from "./ListTab.vue";
import MapTab from "./MapTab.vue";
import _ from "lodash"

export default {
  name: "MedCardCenters",
  components: {
    SearchSelect,
    ListTab,
    MapTab
  },
  data() {
    return {
      tabs: [
        {
          index: 0,
          name: "На карте",
        },
        {
          index: 1,
          name: "Списком"
        }
      ],
      city: null,
      org: null,
      items: [],
      showAs: 0
    }
  },
  async mounted() {
    const loader = this.$loading.show();

    try {
      const {data} = await window.axios.get('/med-cards/centers')

      this.items = data;
    } catch (e) {

    } finally {
      loader.hide()
    }
  },
  computed:{
    showAsComponent(){
      if(this.showAs === 0) {
        return "ListTab"
      }else if (this.showAs === 1) {
        return "MapTab"
      }
    },
    availableCities() {
      const { items } = this;

      return [...new Map(items.map(item =>
          [item['cityId'], item])).values()];
    },
    availableCenters() {
      let { items } = this

      if(this.city) {
        items = items.filter(item => item.cityId === this.city)
      }

      return [...new Map(items.map(item => [item['main_assist'], item])).values()];
    },
    filteredCenters() {
      let { items } = this

      if(this.city) {
        items = items.filter(item => item.cityId === this.city)
      }

      if(this.org) {
        items = items.filter(item => item.main_assist === this.org.main_assist)
      }

      return items;
    },
  },
  methods: {
    changeDisplay(index) {
      this.showAs = index
    }
  },

}
</script>

<style scoped lang="scss">
.centers-tabs{
  background: #FFFFFF;
  border: 1px solid #a6a6a6;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 2px 3px;
  margin-bottom: 20px;
  .tab {
    height: 24px;
    background: none;
    color: #636363;
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    &.active{
      box-shadow: none;
      color: #2AA65C;
    }
  }
}
.form-group{
  padding: 0;
}
</style>
<template>
  <div class="wrapper" v-if="city">
    <table>
      <thead>
      <tr>
        <th>Адрес</th>
        <th>Телефон</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in options">
        <td>{{ item.name }}</td>
        <td>
              <span  v-for="phone in arrayOfPhones(item.phone)">
                {{ phone }}  <br>
              </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    city: Number
  },
  computed: {
    arrayOfPhones() {
      return phones => phones ? phones.split(';'):[]
    }
  },
  mounted() {
    console.log('list tab')
  }
}
</script>

<style scoped lang="scss">
table{
  border-collapse: collapse;
  width: 100%;
  thead{
    background: #E7E7E7;
    th{
      padding: 12px;
      border-collapse: separate;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;

      color: #2A353D;

      &:first-child{
        border-top-left-radius: 12px;
      }
      &:last-child{
        border-top-right-radius: 12px;
      }
    }
  }
  tbody {
    tr{
      border: 1px solid #E7E7E7;
      td{
        border: 1px solid #E7E7E7;
        padding: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &:first-child{
          width: 65%;
        }
        &:last-child{
          text-align: center;
        }
      }
    }

  }
}
</style>
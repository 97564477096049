<template>
  <MedCardInsuredItem :item="selectedItem" :family-members="familyMembers"/>
</template>

<script>
import MedCardInsuredItem from "./InsuredItem.vue";

export default {
  name: "MedCardInsuredList",
  components: {MedCardInsuredItem},
  data() {
    return {
      items: [],
      selectedMedicalCard: 0
    }
  },
  computed:{
    selectedItem() {
      if (this.items.length) {
        this.$emit('medical-id-selected', this.items[this.selectedMedicalCard]['medicalID'])
        return this.items[this.selectedMedicalCard]
      }

      return null;
    },
    familyMembers() {
      return this?.selectedItem?.familyMembers;
    }
  },
  async created() {
    const loader = this.$loading.show();

    try {
      const {data} = await window.axios.get('/med-cards')

      this.items = data;
    } catch (e) {

    } finally {
      loader.hide()
    }
  }
}
</script>